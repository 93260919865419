.home {
	.product-grid-item {
		margin: 10px 0 0 0;
	}

	.category_feature {
		.c-product {
			padding-bottom: 20px;
		}
	}
}

.list_product {
	.heading{
		margin-top: 0;

		.head-label {
			padding-right: 0;
		}
	}

	.thumb {
		text-align: center;
	}

	.product-grid-item {
		.name {
			a {
				font-weight: bold;
			}
		}

		.filter-name {
			font-weight: normal;
		}
	}

	#product-wrapper {
		clear: both;
	}

	#show-more {
		border: 1px solid $divider-color;
		color: $brand-3-color;
		background-image: none;
		margin-top: 30px;
		border-radius: 4px;
		padding: 5px 25px;
		background-color: white;

		.fa {
			margin-left: 5px;
		}
	}
}

.product-grid-item {
	margin-bottom: 0;
	z-index: 10;

	.name {
		padding: 0;
		margin: 10px 0 10px 0;
		text-transform: uppercase;
		color: $text-color;
		font-weight: bold;

		a {
			text-transform: uppercase;
			color: $text-color;
		}
	}

	.img-wrapper {
		display: inline-block;
		overflow: hidden;

		img {
			transition: all .8s ease-in-out;

			&:hover {
				transform:scale(1.05);
				-ms-transform:scale(1.05); /* IE 9 */
				-moz-transform:scale(1.05); //Firefox
				-webkit-transform:scale(1.05); /* Safari and Chrome */
				-o-transform:scale(1.05); /* Opera */
			}
		}
	}

	.infos {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
		display: none;

		.detail {
			float: right;

			.glyphicon-chevron-right {
				color: $brand-2-color;
				font-size: 8px;
				vertical-align: top;
				margin-top: 5px;
			}
		}

		.contact-link {
			&:hover {
				color: #e32a2d;
			}
		}

		.filter-name {
			font-weight: bold;
		}
	}
}

.detail_product {
	.info-section {
		.info_product {
			th {
				padding: 3px 0;
			}
		}
	}
	header.name {

		h1 {
			text-transform: uppercase;
			font-size: 18px;
			font-weight: bold;
		}
	}

	.images {
		padding: 0;

		// Khu soi anh sản phẩm
		.sp-wrap {
			padding: 0;
			border: none !important;
			margin: 0;

			.sp-thumbs {
				a {
					margin-right: 10px;
				}
			}
		}
	}

	.border_info {
    border: 1px solid #CCC;
    float: left;
    padding: 15px;
    width: 100%;
    border-radius: 6px;
    -webkit-box-shadow: inset 0px 0px 5px 3px rgba(204,204,204,0.8);
    -moz-box-shadow: inset 0px 0px 5px 3px rgba(204,204,204,0.8);
    box-shadow: inset 0px 0px 5px 3px rgba(204,204,204,0.8);
  }

	.promotion {
		margin: 10px -15px;

		td, th {
			padding: 9px 0;
		}
	}
	.border_top_bottom_ccc {
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}
  @media (min-width: 1200px) {
		.border_info {
		    min-height: 521px;
		}
	}

	.info-section {
		.detail-infos {
			margin: 0;
			padding: 0;
			float: left;
			width: 100%;

			li {
				float: left;
				list-style: none;
				border-right: 1px solid $text-color;
				margin: 7px 5px 5px 0;
				padding-right: 5px;
				line-height: 15px;

				&:last-child {
					border-right: none;
				}

				a {
					color: $brand-2-color;
					cursor: pointer;
				}
			}
		}

		.price {

			p {
				margin: 0;
				padding: 0;
			}

			.old-price {
				del {
					color: #999;
				}
			}

			.new-price {
				span {
					color: $brand-5-color;
					font-size: 18px;
				}
			}
		}

		.promotion-warranty {
			float: left;
			width: 100%;
			margin-bottom: 10px;
			padding: 0 10px;
			font-weight: bold;

			.icon_tick {
				background-position: -111px -225px;
				width: 14px;
				height: 15px;
				margin: 2px 12px 0 0;
				display: block;
				float: left;
			}

			> p {
				margin: 0;

				&.promotion {
					color: $brand-5-color;
				}
			}
		}

		.commit {
			background-color: #f4f4f4;
			float: left;
			width: 100%;
			padding: 0 10px;
			margin-bottom: 15px;

			.title {
				color: $brand-2-color;
				font-weight: bold;
				text-transform: uppercase;
				margin-top: 10px;
			}

			.icon_tick {
				background-position: -111px -225px;
				width: 14px;
				height: 15px;
				margin: 2px 12px 0 0;
				display: block;
				float: left;
			}
		}

		.group-button {
			float: left;
			width: 100%;
			text-align: center;
			margin: 15px 0;
			a {
				width: 100%;
				display: inline-block;
			}
			.btn-1 {
				background-color: $brand-2-color;
				border-color: $brand-2-color;
				color: white;
				display: inline-block;
				border-radius: 5px;

				width: 205px;
				text-transform: uppercase;
				font-size: 16px;
				line-height: 46px;
				cursor: pointer;
				margin-bottom: 5px;

				@media screen and (max-width: $tablet) {
					width: 205px;
					text-align: center;
					padding: 8px 0;
				}

				.icon-tick {
					background-position: -239px -8px;
					width: 30px;
					height: 20px;
					display: block;
					float: left;
					margin: 10px 15px;
					@media screen and (max-width: $tablet) {
						display: none;
					}
				}
				.icon_cart_btn {
			    background-position: -253px -215px;
			    display: inline-block;
			    width: 30px;
			    height: 26px;
			    margin: 10px;
			    float: left;
			    @media screen and (max-width: $tablet) {
					}
			  }
			}

			.btn-2 {
				background-color: white;
				border: 1px solid $brand-2-color;
				color: $brand-2-color;
				width: calc(33.33% - 12px);
				@media screen and (max-width: 375px) {
					display: inline-block;
				}

				margin: 0 6px;
				font-size: 12px;
				line-height: 40px;
				font-weight: bold;
				cursor: pointer;

				@media screen and (max-width: $tablet) {
					width: 205px;
					text-align: center;
			    padding: 8px 0;
				}

				.icon-cart {
					background-position: -279px -5px;
					width: 30px;
					height: 25px;
					display: block;
					float: left;
					margin: 6px 10px;
					@media screen and (max-width: $tablet) {
						display: none;
					}
				}
			}

			.btn-3 {
				border-color: $brand-2-color;
				background-color: $brand-2-color;
				color: white;
				display: inline-block;
				text-transform: uppercase;
				cursor: pointer;
				width: 205px;
				border-radius: 5px;


				@media screen and (max-width: $tablet) {
					width: 205px;
			    text-align: center;
			    padding: 8px 0;
				}

				.icon-phone {
					background-position: -323px -3px;
					width: 33px;
					height: 26px;
					display: block;
					float: left;
					margin: 10px;
					@media screen and (max-width: $tablet) {
					}
				}

				.title-hotline {
					float: none;
					margin: 3px;
					font-size: 19px;
					text-align: center;
					line-height: 16px;

					@media screen and (min-width: $tablet) {
					}
					@media screen and (max-width: $tablet) {
						width: 202px;
					}
					.title {
						font-size: 11px;

						@media screen and (max-width: $tablet) {
							font-size: 11px;
							display: inline-block;
							margin-bottom: 3px;
						}
					}
				}
			}
		}

		.services {
			background-color: #f4f4f4;
			float: left;
			width: 100%;
			padding: 10px;
			margin: 0;

			li {
				float: left;
				list-style: none;
				width: 25%;

				@media screen and (max-width: $tablet) {
					width: 100%;
					margin-bottom: 10px;
				}
			}

			.icon-1 {
				background-position: -5px -171px;
				width: 34px;
				height: 31px;
				display: block;
				float: left;
				background-size: 400px;
			}

			.icon-2 {
				background-position: -48px -169px;
				width: 33px;
				height: 33px;
				display: block;
				float: left;
				background-size: 400px;
			}

			.icon-3 {
				background-position: -92px -170px;
				width: 33px;
				height: 33px;
				display: block;
				float: left;
				background-size: 400px;
			}

			.icon-4 {
				background-position: -221px -218px;
				width: 33px;
				height: 33px;
				display: block;
				float: left;
				background-size: 504px;
			}

			.text {
				float: left;
				width: calc(100% - 48px);
				margin-left: 10px;
				margin-bottom: 0;
			}
		}
	}

	.product_des {
		.nav-tabs {
			> li {
				font-size: 13px;
				text-transform: uppercase;
				border-radius: 0;

				&.active {
					> a {
						color: white;
						background-color: $brand-2-color;
						border: 1px solid $brand-2-color !important;
					}
				}

				&:first-child {
					> a {
						border-left: 1px solid #d4d4d4;
					}
				}

				> a {
					border-radius: 0;
					margin-right: 0;
					padding: 7px 15px;
					font-size: 14px;
					border: 1px solid #d4d4d4;
					border-left: 0;

					&:hover {
						color: white;
						background-color: $brand-2-color;
						border: 1px solid $brand-2-color;
					}
				}
			}
		}
	}

	.owl-carousel {
		.owl-stage {
			@media screen and (min-width: $tablet + 1px) {
				transform: translate3d(0px, 0px, 0px) !important;
			}
		}

		.owl-nav {
			top: -7px;

			@media screen and (min-width: $tablet + 1px) {
				display: none !important;
			}

			.owl-prev {
				left: 0;
			}

			.owl-next {
				right: 0;
			}
		}
	}
}

.list_product,
.filter_product {
	.hover-border {
		border: 1px solid white;
		margin-bottom: 3px;
		box-shadow: 2px 2px 3px white;
		padding-top: 10px;
		@media screen and (min-width: $tablet + 1px) {
			width: calc(33.3333% - 3px);
			margin-right: 3px;
		}

		&:hover {
			border: 1px solid $divider-color;
			box-shadow: 2px 2px 3px #c1c1c1;
		}
	}
}

.product-view {
	.related {
		.auto-clear {
			.item {
				padding-top: 15px;
			}
			>div:first-child {
				border-left: 1px solid #eee;
			}
			@media screen and (min-width: $tablet + 1px) {
				>div:nth-child(5),>div:nth-child(9) {
					border-left: 1px solid #eee;
				}
			}
		}
	}
}