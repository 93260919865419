.contact {
	address {
		margin: 15px 0 30px 0;
	}

	#ContactIndexForm {
		.note-form {
			margin-bottom: 15px;
		}

		.btn-submit {
			@media screen and (min-width: $tablet + 1px) {
				margin-top: 20px;
			}
		}

		.note-required {
			color: red;
		}
	}

	// Sửa lỗi padding Recaptcha che mất đối tượng khác
	.embed-responsive-16by9 {
		padding-bottom: 25.25% !important;
	}
}