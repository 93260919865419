#sub-footer-1 {
	float: left;
	width: 100%;
	background-color: $brand-6-color;
	padding: 15px 0;
	margin-top: 20px;

	.services {
		.icon-1 {
			background-position: -8px -256px;
			width: 49px;
			height: 49px;
			display: block;
			float: left;
		}

		.icon-2 {
			background-position: -73px -253px;
			width: 48px;
			height: 46px;
			display: block;
			float: left;
		}

		.icon-3 {
			background-position: -139px -256px;
			width: 49px;
			height: 45px;
			display: block;
			float: left;
		}

		.icon-4 {
			background-position: -203px -255px;
			width: 49px;
			height: 48px;
			display: block;
			float: left;
		}

		.text {
			float: left;
			width: calc(100% - 60px);
			margin-left: 10px;
			margin-bottom: 0;

			@media screen and (max-width: $tablet) {
				margin-bottom: 10px;
			}

			.big {
				font-weight: bold;
				width: 100%;
				display: block;
				text-transform: uppercase;
				font-size: 14px;
			}

			.small {
				font-size: 13px;
			}

			.hotline {
				color: $brand-2-color;
			}
		}
	}
}

#sub-footer-2 {
	float: left;
	width: 100%;
	background-color: white;
	padding: 20px 0;

	&.line-top {
		border-top: 1px solid $brand-1-color;
	}

	h4 {
		text-transform: uppercase;
		font-weight: bold;
		border-bottom: 1px solid $divider-color;
		margin-bottom: 8px;
		padding-bottom: 3px;

		@media screen and (max-width: $tablet) {
			height: 48px;
			margin: 0 8px 8px 8px;
			padding: 0;
		}
	}

	table {
		width: 100%;

		tbody {
			tr {
				td {
					width: 50%;
					float: left;

					@media screen and (max-width: $tablet) {
						width: 50%;
						margin-bottom: 10px;
					}

					img {
						@media screen and (max-width: $tablet) {
							max-width: 87%;
							height: auto !important;
						}
					}
				}
			}
		}
	}

	.support-wrapper,
	.social-wrapper {
		@media screen and (max-width: $tablet) {
			width: 50%;
			float: left;
		}
	}

	.supporters {
		padding: 0;
		margin: 0 0 10px 0;
		float: left;
		width: 100%;

		@media screen and (max-width: $tablet) {
			margin: 0 8px;
			width: auto;
		}

		li {
			list-style: none;
			float: left;
			width: 100%;

			.fa {
				margin-right: 5px;
			}

			.phone {
				color: $brand-5-color;
			}
		}
	}

	.socials {
		@media screen and (max-width: $tablet) {
			margin: 0 8px;
			width: auto;
		}

		.fa {
			font-size: 25px;
			margin-right: 7px;
		}
	}
}

#footer {
	color: white;
	padding: 0;
	font-size: 15px;
	float: left;
	width: 100%;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$brand-1-color+0,215002+100 */
	background: $brand-1-color; /* Old browsers */
	background: -moz-linear-gradient(top, $brand-1-color 0%, darken($brand-1-color,5%) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $brand-1-color 0%,darken($brand-1-color,5%) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $brand-1-color 0%,darken($brand-1-color,5%) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$brand-1-color', endColorstr='darken($brand-1-color,5%)',GradientType=0 ); /* IE6-9 */

	address {
		margin: 20px 0 15px 0;

		@media screen and (max-width: $tablet) {
			margin: 15px 0;
		}
	}

	table {
		tbody {
			tr {
				td {
					width: 25%;
					float: left;
					display: block;
					padding-right: 30px;

					@media screen and (max-width: $tablet) {
						width: 50%;
						padding: 0 5px;
						float: left;
					}

					&:nth-child(3) {
						@media screen and (max-width: $tablet) {
							clear: left;
						}
					}

					img {
						@media screen and (max-width: $tablet) {
							max-width: 100%;
							height: auto !important;
						}
					}

					h4 {
						color: white;
						margin: 5px 0 10px 0;
						height: 44px;
						font-weight: bold;
					}

					&:nth-child(4) {
						p {
							@media screen and (max-width: $tablet) {
								word-break: break-all;
							}
						}
					}
				}
			}
		}
	}

	p {
		font-size: 13px;
		line-height: 23px;
		color: white;
		margin: 0 0 15px 0;
	}

	a {
		color: white;

		&:hover {
			color: white;
		}
	}
}

#copyright {
	float: left;
	width: 100%;
	background-color: $brand-1-color;
	color: #bfbfbf;
	text-align: center;
	padding-top: 15px;

	.footer-tags {
		margin-bottom: 5px;

		a {
			color: white;
		}
	}
}