#header_top {
	background-color: $brand-6-color;
	float: left;
	width: 100%;
	border-bottom: 1px solid $divider-color;

	.left-menu {
		float: left;
		margin: 0;
		padding: 0;

		li {
			float: left;
			list-style: none;
			border-right: 1px solid $divider-color;

			&:last-child {
				border-right: none;
			}

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			a {
				line-height: 30px;
				padding: 0 10px;
				font-size: 13px;
				color: $gray-text-color;
			}
		}
	}

	.slogan {
		float: left;
		margin-left: 0;
		font-size: 13px;
		color: $gray-text-color;
		width: 422px;

		@media screen and (max-width: 1200px) {
			display: none;
		}

		.freeship {
			text-align: center;
			line-height: 30px;
			margin: 0;
			float: left;

			h1 {
				font-size: inherit;
				line-height: inherit;
			}
		}
	}

	.right-menu {
		margin: 0;
		padding: 0;
		float: right;

		@media screen and (max-width: $tablet + 1px) {
			display: none;
		}

		> li {
			list-style: none;
			float: left;
			border-left: 1px solid $divider-color;

			&:first-child {
				border-left: none;
			}

			.icon-flag {
				background-position: -8px -224px;
				width: 20px;
				height: 15px;
				float: left;
				margin: 8px 10px;
			}

			> a {
				line-height: 30px;
				padding: 0 10px;
				font-size: 13px;
				color: $gray-text-color;
			}

			.dropdown {
				.dropdown-toggle {
					background: transparent !important;
					border: none;
					box-shadow: none;
					color: $gray-text-color;
					font-size: 13px;
				}

				.fa {
					margin-left: 5px;
				}

				&:hover {
					.dropdown-menu {
						display: block;
					}
				}

				.dropdown-menu {
					border-radius: 0;
					border-color: $divider-color;

					li {
						a {
							font-size: 13px;
							color: $gray-text-color;

							&:hover {
								background-image: none;
								background: transparent;
								color: $brand-2-color;
							}
						}
					}
				}
			}
		}
	}

	.navbar-toggle {
		float: left;
		margin: 0;
		padding-left: 0;

		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}

		.icon-bar {
			background-color: white;
			width: 15px;
			height: 1px;
		}
	}
}

#header {
	padding: 10px 0 10px 0;
	position: relative;

	@media screen and (max-width: $tablet) {
		border-bottom: 1px solid $brand-1-color;
		float: left;
		width: 100%;

		.m_cart {
			position: absolute;
	    right: 0;
	    top: 16px;
		}
	}

	.logo {
		img {
			@media screen and (max-width: $tablet) {
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
	}

	.searchbox-wrapper {
		text-align: right;
		margin: 13px 0 0 0;

		@media screen and (max-width: $tablet) {
			position: relative;

			.input-group {
				margin: 0;
			}
		}

		.banners {
			list-style: none;
			float: left;
			margin: 5px 0 0 0;
			padding: 0;

			@media screen and (max-width: 1200px) {
				display: none;
			}

			li {
				float: left;
				background-image: url('img/header_banner_icons.png');
				background-size: 75px;
				background-repeat: no-repeat;
				padding-left: 42px;

				&:nth-child(1) {
					background-position: 0 -87px;
					width: 230px;
				}

				&:nth-child(2) {
					background-position: 0 -42px;
					width: 232px;
				}

				&:nth-child(3) {
					background-position: 0 0;
					width: 200px;
				}

				.big-label {
					text-transform: uppercase;
					font-weight: bold;
					text-align: left;
					margin: 0;
					letter-spacing: 0.1px;
				}

				.small-label {
					text-align: left;
					font-size: 11px;
					line-height: 15px;
					margin: 0;
				}
			}
		}
	}

	.navbar-toggle {
		margin: 5px 0 0 0;

		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}

		@media screen and (max-width: $tablet) {
			padding: 10px 10px 0 10px;
			margin: 0;
			float: right;
		}

		.icon-bar {
			background-color: $brand-1-color;
		}

		.glyphicon-search {
			margin-top: 5px;
			color: $brand-1-color;
		}
	}

	.searchbox-wrapper {
		margin-top: 15px;
		float: left;
		width: 100%;

		@media screen and (max-width: $tablet + 1px) {
		}

		#form-filter-basic {
			float: left;
			position: relative;

			@media screen and (max-width: $tablet) {
				.input-group-btn {
					float: left;
				}
			}

			#srch-term {
				border: 1px solid $divider-color;
				border-radius: 0;
				width: 310px;
				@media screen and (max-width: $tablet) {
					width: 47%;
				}
				box-shadow: none;
			}

			.filter-category-select {
				border: 1px solid $divider-color;
				border-right: 0;
					@media screen and (max-width: $tablet) {
					  float: left;
				    width: 119px;
				  }

				#FilterProductCategory {
					-webkit-appearance: none;
					-moz-appearance: none;
					border: none;
					height: 32px;
					box-shadow: none;
					background: url(img/select-down-arrow.png) no-repeat;
					background-size: 10px;
					background-position: 195px 14px;
					@media screen and (max-width: $tablet) {
						width: 123px;
					}
				}

				.fa {
					// font-size: 15px;
					// position: absolute;
					// top: 8px;
					// right: 8px;
					// z-index: 100;
					// display: none;
				}
			}

			.btn-search {
				background-color: $brand-2-color;
				height: 34px;
				float: left;
				box-shadow: none;
				border: none;
				border-radius: 0;
				padding: 0 10px;
				text-shadow: none;
					@media screen and (max-width: $tablet) {
						width: 35px;
					}

				.icon_search {
					background-position: -45px -224px;
					width: 18px;
					height: 18px;
					display: block;
				}
			}
		}

		.trending {
			.title {
				color: $brand-5-color;
			}

			.vline {
				color: $text-color;
			}

			a {
				color: $brand-2-color;
			}

			a,
			span {
				display: inline-block;
				float: left;
			}
		}
	}

	.cart {
		line-height: 32px;
		margin-top: 22px;
		float: right;
		width: 195px;

		@media screen and (max-width: $tablet + 1px) {
			width: 50px;
			margin: 0;
		}

		.input-group-btn {
			background-color: $brand-2-color;
			padding: 0 10px;

			.icon_cart {
				background-position: -71px -219px;
				width: 26px;
				height: 26px;
				display: block;
			}
		}

		.sp {
			@media screen and (max-width: $tablet + 1px) {
				display: none;
			}
		}

		.link_cart {
			padding-left: 10px;
			display: block;
			border: 1px solid $divider-color;
			line-height: 32px;
			border-left: none;

			@media screen and (max-width: $tablet) {
				float: left;
				padding: 0 10px;
			}
		}
	}
}