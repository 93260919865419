#nav-menu {
	background-color: $brand-1-color;
	border-bottom: 1px solid #b4b4b4;
	box-shadow: 0 2px 3px #b4b4b4;

	&.stick {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 2;
	}
	@media screen and (max-width: $tablet) {
		float: left;
		width: 100%;
		padding: 0;
		border: none;
		box-shadow: none;

		.collapse {
			display: block;

			.nav>li {
				display: none;
			}

			&.in {
				.nav>li {
					display: block;
				}
			}
		}
	}

	.navbar-header {
		@media screen and (min-width: $tablet + 1px) {
			display: none;
		}
	}

	.navbar-collapse {
		@media screen and (max-width: $tablet) {
			border: none;
		}
	}

	.navbar {
		min-height: 40px;

		@media screen and (max-width: $tablet) {
			div#bs-example-navbar-collapse-1 {
				overflow-y: inherit;
			}
			float: left;
			width: 100%;
			background-color: #3e3e3e;
		}

		.navbar-nav {
			@media screen and (max-width: $tablet) {
				margin: 0 -15px;
			}

			> li {
				> a {
					text-transform: uppercase;
					color: white;
					text-align: right;
					margin: 0 15px;

					@media screen and (max-width: $tablet) {
						margin: 0;
						text-align: left;
						background-color: #3e3e3e;
					}

					&:hover,
					&:focus {
						background-color: $brand-2-color;
						color: white;
						outline: none;
					}
				}
			}

			#product-nav-tree {
				border: none;
				display: block;
				border-radius: 0;
				white-space: nowrap;
				font-size: 15px;
				font-weight: bold;
				background-color: $brand-2-color;
				line-height: 39px;
				margin: 0;
				padding: 0 15px;
				z-index: 1000;

				@media screen and (max-width: $tablet) {
					width: 212px;
					padding: 0;
				}

				> a {	// Danh mục sản phẩm
					display: block;
					margin: 0;
					padding-left: 0;
					padding-right: 0;
					width: 232px;
					text-align: center;
					background-color: $brand-2-color;
					white-space: normal;

					@media screen and (max-width: $tablet) {
						width: 100%;
						padding-top: 15px;
    				padding-bottom: 15px;
					}
				}

				.fa {
					float: right;
					font-size: 17px;
					cursor: pointer;

				}
				@media screen and (max-width: $tablet) {
					.fa-bars {
						display: none;
					}
				}

				.caret-wrapper {
					float: right;
					padding: 2px 10px;
					cursor: pointer;

					@media screen and (min-width: $tablet + 1px) {
						display: none;
					}
				}

				> ul {
					border-radius: 0;
					padding: 0;
					@media screen and (max-width: $tablet) {
						width: calc(100% + 50%);
						overflow-y: scroll;
					}

					> li {
						@media screen and (max-width: $tablet) {
							>img, .white-bg, .title-parent {
								display: none;
							}
						}
						.fa-down {
					    transform: rotate(90deg);
					    transition: .5s;
						}
						.mblock {
							display: block;
					    float: left;
					    position: static;
					    width: 100%;
					    padding-left: 15px;
					    background-color: white;
					    li {
					    	width: calc(100% - 15px);

					    }
						}

						&:nth-child(n+13):not(.show-more) {
							@media screen and (min-width: $tablet + 1px) {
								display: none;
							}
						}

						&.showing-more {
							display: block !important;
						}

						> a {
							text-transform: uppercase;
							white-space: normal;
							position: relative;
						}

						&.current {
							> a {
								color: $brand-2-color;
								font-weight: bold;
							}
						}
					}
				}

				li {
					width: 100%;
					line-height: 30px;
					padding: 0;
					border-bottom: 1px solid $divider-color;
					min-width: 260px;

					&:hover {
						> a {
							color: $brand-2-color;;
						}

						> ul {
							@media screen and (min-width: $tablet + 1px) {
								display: block;
							}
						}
					}

					&.child {
						padding: 0 10px 0 27px;
						list-style-type: circle;
						list-style-position: inside;
					}

					&:last-child {
						border-bottom: none;
					}

					a {
						color: $text-color;
						font-size: 13px;
						padding: 5px 0 5px 15px;
						background-image: none;
						background-color: white;
						line-height: 22px;

						&:hover {
							color: $brand-2-color;
						}

						.fa {
							float: right;
							font-size: 20px;
							line-height: 31px;
							transition: .5s;
							padding: 0 15px;
							background: white;
					    position: absolute;
					    top: 0;
					    right: 0;
						}
					}

					> ul {
						display: none;
						position: static;
						margin: 0;
						padding: 0;
						float: left;
						box-shadow: none;
						border-left: none;
						border-right: none;
						border-bottom: none;
						border-top: 1px solid $divider-color;
						border-radius: 0;
						min-width: initial;
						position: absolute;
						top: 0;
						left: 100%;
						display: none;

						@media screen and (min-width: $tablet + 1px) {
							border: 1px solid $divider-color;
						}

						li {
							margin: 0;
							padding: 0;
							width: 100%;
							float: left;

							a {
								padding: 5px 15px;

								&:hover,
								&:focus {
									background-color: white;
									background-image: none;
									text-decoration: underline;
									color: $brand-2-color;
								}
							}
						}
					}
				}

				.submenu-caret-wrapper {
					display: inline-block;

					@media screen and (min-width: $tablet + 1px) {
						display: none;
					}

					@media screen and (max-width: $tablet) {
						padding: 0px 10px !important;
						float: right;
						display: block;
					}

					.caret {
						margin: 0 0 0 10px;

						@media screen and (max-width: $tablet) {
							margin: 0;
							height: auto;
							line-height: inherit;
							padding: 0;
							display: inline-block;
						}
					}
				}

				.show-more {
					text-align: center;
					line-height: 0;

					.fa {
						color: $brand-2-color;
						float: none;
					}
				}
			}
		}
	}
}

#product-nav-tree,
// #product-nav-tree-othersite,
#product-nav-tree-slideshow {
	&.banner {
		li {
			> ul {
				width: 587px;
				left: calc(100% + 4px) !important;
				top: 35px !important;

				li {
					width: 50% !important;
					border: none !important;

					a {
						font-size: 13px !important;
						color: $brand-2-color !important;

						&:hover {
							color: $text-color !important;
						}
					}
				}
			}
		}

		> ul {
			> li {
				position: static;

				.white-bg {
					position: absolute;
					top: 0;
					left: calc(100% + 3px) !important;
					width: 877px;
					height: 415px;
					background-color: white;
					display: none;
					border-bottom: 1px solid #bdbdbd;
					border-right: 1px solid #bdbdbd;
					border-left: 1px solid #bdbdbd;
				}

				&:hover {
					img {
						display: block;
						margin-top: 30px;
					}

					> .white-bg {
						display: block;
					}

					> .title-parent {
						display: block;
					}
				}

				ul {
					border: none !important;
				}

				img {
					position: absolute;
					top: 0;
					left: 855px;
					width: 270px;
					height: 339px;
					z-index: 10;
					display: none;
				}

				.title-parent {
					position: absolute;
					top: 0;
					left: calc(100% + 19px);
					z-index: 10000;
					text-transform: uppercase;
					font-weight: normal;
					border-bottom: 1px solid $divider-color;
					width: 560px;
					line-height: 29px;
					border-left: 2px solid $brand-2-color;
					padding-left: 10px;
					margin: 2px 0;
					display: none;
				}
			}
		}
	}
}


@media screen and (max-width: $tablet) {
	.fixed-top{
		position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
	}
}