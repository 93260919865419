// Thống kê truy cập trên sidebar
.sidebar .counter table {
	width: 160px;
	margin: 0 auto;
	font-family: Tahoma;
}

.sidebar .counter table th {
	text-align: left;
	font-weight: normal;
	padding-left: 20px;
}

.sidebar .counter table td {
	text-align: right;
}

.sidebar .counter tr.online th {
	background: url(img/s_counter1.png) left 2px no-repeat;
}

.sidebar .counter tr.yesterday th {
	background: url(img/s_counter2.png) left 2px no-repeat;
}

.sidebar .counter tr.today th {
	background: url(img/s_counter3.png) left 2px no-repeat;
}

.sidebar .counter tr.week th {
	background: url(img/s_counter4.png) left 2px no-repeat;
}

.sidebar .counter tr.month th {
	background: url(img/s_counter5.png) left 2px no-repeat;
}

.sidebar .counter tr.year th {
	background: url(img/s_counter1.png) left 2px no-repeat;
}

.sidebar .counter tr.total th {
	background: url(img/s_counter_total.png) left 2px no-repeat;
}
.sidebar .counter tr.total_top th{
	text-align: center;
	line-height: 30px;
	padding: 0 0 5px 0;
}
.sidebar .counter #counter{
	text-align: center;
}
.sidebar .counter tr.total_top p {
	font-weight: bold;
	text-align: center;
	background: #333;
	color: #fff;
	font-size: 11px;
	margin: 0 auto 5px;
	font-size: 15px;
	border-radius: 3px;
	display: inline;
	padding: 2px 4px;
}
.sidebar .counter tr.total_top p span{
	padding: 0 4px;
	border-right: 1px solid #666;
}
.sidebar .counter tr.total_top p span:last-of-type{
	border: none;
}