aside.box {
	border: 1px solid $divider-color;
	float: left;
	margin-bottom: 20px;
	width: 100%;

	.title {
		border: none;
		background-color: $brand-2-color;
		color: white;
		padding: 10px;
		box-sizing: border-box;
		text-transform: uppercase;

		.fa {
			margin-right: 5px;
		}
	}

	&.support {
		.supporters {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0 10px;

			> li {
				float: left;
				width: 100%;
				border-bottom: 1px solid #ddd;
				padding-bottom: 5px;
				margin-bottom: 5px;

				&:last-child {
					border-bottom: 0;
				}

				.supporter {
					list-style: none;
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;

					li {
						float: left;
						width: 100%;

						&.name {
							font-weight: bold;
						}

						&.phone {
							color: red;
						}

						&.email {
							color: green;
						}

						.glyphicon {
							margin-right: 10px;
							color: black;
						}
					}
				}
			}
		}

		.social {
			text-align: center;
			list-style: none;
			float: left;
			width: 100%;
			margin: 10px 0 0 0;
			padding: 0;

			li {
				display: inline-block;
				height: 27px;
				width: 27px;
				margin: 0;

				&.facebook {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -7px -5px;
					background-size: 154px;
				}

				&.k {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -34px -5px;
					background-size: 154px;
				}

				&.gmail {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -62px -5px;
					background-size: 154px;
				}

				&.gplus {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -91px -5px;
					background-size: 154px;
				}
			}
		}
	}

	&.s_post {
		.post-thumb-list {
			float: left;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				background: white;
				width: 100%;
				float: left;
				padding: 5px;
				padding-left: 5px;
				border-bottom: 1px solid #efefef;
				position: relative;

				a {
					width: 100%;
					float: left;
					height: 100%;
					color: $text-color;

					.thumb {
						float: left;

						img {
							margin-right:: 10px;
						}
					}

					.name {
						float: left;
						width: calc(100% - 90px);
					}
				}
			}
		}
	}

	&.likebox {
		.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
			width: 100% !important;
		}
	}

	&.adv {
		ul {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}

	&.category {
		border: 1px solid #cecece;

		#tree {
			> li {
				padding: 0;
			}
		}

		.show-more {
			text-align: center;
			float: left;
			width: 100%;
		}
	}

	&.maker,
	&.price {
		.nav {
			li {
				padding: 0 10px;
				list-style: none;

				.check-maker {
					display: inline-block;
					float: left;
					margin: 13px 15px 0 0;
				}

				.name {
					float: left;
					width: calc(100% - 35px);
					padding: 10px 0;
				}
			}
		}
	}

	&.price {
		a {
			&:hover {
				color: $text-color;
			}
		}
	}

	&.product-other-page {
		border: none;

		#product-nav-tree-othersite {
			border: none;
			display: block;
			border-radius: 0;
			white-space: nowrap;
			font-size: 15px;
			font-weight: bold;
			background-color: $brand-2-color;
			line-height: 39px;
			margin: 0;
			padding: 0 15px;
			z-index: 1;

			> a {	// Danh mục sản phẩm
				display: block;
				margin: 0;
				padding-left: 0;
				padding-right: 0;
				width: 232px;
				text-align: center;
				background-color: $brand-2-color;
				white-space: normal;

				@media screen and (max-width: $tablet) {
					width: 100%;
				}
			}

			.fa {
				float: right;
				font-size: 17px;
				cursor: pointer;

				@media screen and (max-width: $tablet) {
					display: none;
				}
			}

			.caret-wrapper {
				float: right;
				padding: 2px 10px;
				cursor: pointer;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}
			}

			> ul {
				border-radius: 0;
				padding: 0;

				> li {
					&:nth-child(n+13):not(.show-more) {
						@media screen and (min-width: $tablet + 1px) {
							display: none;
						}
					}

					&.showing-more {
						display: block !important;
					}

					> a {
						text-transform: uppercase;
						white-space: normal;
					}

					&.current {
						> a {
							color: $brand-2-color;
							font-weight: bold;
						}
					}
				}
			}

			li {
				width: 100%;
				line-height: 30px;
				padding: 0;
				border-bottom: 1px solid $divider-color;
				min-width: 260px;

				&:hover {
					> a {
						color: $brand-2-color;;
					}

					> ul {
						@media screen and (min-width: $tablet + 1px) {
							display: block;
						}
					}
				}

				&.child {
					padding: 0 10px 0 27px;
					list-style-type: circle;
					list-style-position: inside;
				}

				&:last-child {
					border-bottom: none;
				}

				a {
					color: $text-color;
					font-size: 13px;
					padding: 5px 22px;
					background-image: none;
					background-color: white;
					line-height: 22px;

					&:hover {
						color: $brand-2-color;
					}

					.fa {
						float: right;
						font-size: 20px;
						line-height: 22px;

						@media screen and (max-width: $tablet) {
							display: none;
						}
					}
				}

				> ul {
					display: none;
					position: static;
					margin: 0;
					padding: 0;
					float: left;
					box-shadow: none;
					border-left: none;
					border-right: none;
					border-bottom: none;
					border-top: 1px solid $divider-color;
					border-radius: 0;
					min-width: initial;
					position: absolute;
					top: 0;
					left: 100%;
					display: none;

					@media screen and (min-width: $tablet + 1px) {
						border: 1px solid $divider-color;
					}

					li {
						margin: 0;
						padding: 0;
						width: 100%;
						float: left;

						a {
							padding: 5px 15px;

							&:hover,
							&:focus {
								background-color: white;
								background-image: none;
								text-decoration: underline;
								color: $brand-2-color;
							}
						}
					}
				}
			}

			.submenu-caret-wrapper {
				display: inline-block;

				@media screen and (min-width: $tablet + 1px) {
					display: none;
				}

				@media screen and (max-width: $tablet) {
					padding: 0px 10px !important;
					float: right;
					display: block;
				}

				.caret {
					margin: 0 0 0 10px;

					@media screen and (max-width: $tablet) {
						margin: 0;
						height: auto;
						line-height: inherit;
						padding: 0;
						display: inline-block;
					}
				}
			}

			.show-more {
				text-align: center;
				line-height: 0;

				.fa {
					color: $brand-2-color;
					float: none;
				}
			}

			&.banner {
				li {
					> ul {
						width: 587px;
						left: calc(100% + 4px) !important;
						top: 35px !important;

						li {
							width: 50% !important;
							border: none !important;

							a {
								font-size: 13px !important;
								color: $brand-2-color !important;

								&:hover {
									color: $text-color !important;
								}
							}
						}
					}
				}

				> ul {
					> li {
						position: static;

						.white-bg {
							position: absolute;
							top: 0;
							left: calc(100% + 3px) !important;
							width: 877px;
							height: 415px;
							background-color: white;
							display: none;
							border-bottom: 1px solid #bdbdbd;
							border-right: 1px solid #bdbdbd;
							border-left: 1px solid #bdbdbd;
						}

						&:hover {
							img {
								display: block;
								margin-top: 30px;
							}

							> .white-bg {
								display: block;
							}

							> .title-parent {
								display: block;
							}
						}

						ul {
							border: none !important;
						}

						img {
							position: absolute;
							top: 0;
							left: 855px;
							width: 270px;
							height: 339px;
							z-index: 10;
							display: none;
						}

						.title-parent {
							position: absolute;
							top: 0;
							left: calc(100% + 19px);
							z-index: 10000;
							text-transform: uppercase;
							font-weight: normal;
							border-bottom: 1px solid $divider-color;
							width: 560px;
							line-height: 29px;
							border-left: 2px solid $brand-2-color;
							padding-left: 10px;
							margin: 2px 0;
							display: none;
						}
					}
				}
			}
		}
	}
}

.block_title {
	border: none;

	h2 {
		background-color: $brand-1-color;
		color: white;
		padding: 0 15px;
		box-sizing: border-box;

		.glyphicon {
			margin-right: 10px;
		}
	}
}

#tree {
	list-style: none;
	float: left;
	width: 100%;
	margin: 0;
	padding: 0;

	.caret-wrapper {
		float: right;
		padding: 2px 10px;
		cursor: pointer;
		display: none;
	}

	> .nav {
		> li {
			&:hover {
				> a {
					color: $brand-2-color;;
				}
			}

			&.current {
				a {
					color: $brand-2-color;
					font-weight: bold;
				}
			}

			> a {
				&:hover {
					color: $brand-2-color;;
				}
			}
		}
	}

	.nav {
		li {
			float: left;
			width: 100%;
			line-height: 30px;
			padding: 0;
			border-bottom: 1px solid $divider-color;

			&.current {
				> ul {
					display: block
				}
			}

			&.child {
				padding: 0 10px 0 27px;
				list-style-type: circle;
				list-style-position: inside;
			}

			&:last-child {
				border-bottom: none;
			}

			a {
				color: $text-color;
				font-size: 13px;
				padding: 5px 10px;

				.fa {
					float: right;
					font-size: 20px;
					line-height: 30px;
				}
			}

			> ul {
				display: none;
				position: static;
				margin: 0;
				padding: 0;
				float: left;
				box-shadow: none;
				border-left: none;
				border-right: none;
				border-bottom: none;
				border-top: 1px solid $divider-color;
				border-radius: 0;
				min-width: initial;
				position: absolute;
				top: 0;
				left: 100%;

				@media screen and (min-width: $tablet + 1px) {
					border: 1px solid $divider-color;
				}

				li {
					margin: 0;
					padding: 0;
					width: 100%;
					float: left;

					a {
						padding: 5px 15px;

						&:hover,
						&:focus {
							background-color: white;
							background-image: none;
							text-decoration: underline;
							color: $brand-2-color;
						}
					}
				}
			}
		}
	}

}