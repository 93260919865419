.detail_video,
.list_video_category,
.list_all_video,
.list_video {
	.item-video {
		margin-bottom: 15px;

		.name {
			margin: 10px 0 10px 0;
			float: left;
			width: 100%;

			a {
				color: $text-color;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		.thumb {
			float: left;
			width: 100%;

			a {
				float: left;
				width: 100%;

				img {
					float: left;
					width: 100%;
				}
			}

			&:hover {
				.youtube-play {
					background: url('img/youtube_preview_hover.png') 0 0 no-repeat;
					position: absolute;
					top: calc(50% - 40px);
					left: calc(50% - 27px);
					height: 38px;
					width: 55px;
					background-size: 55px;
				}
			}

			.youtube-play {
				background: url('img/youtube_preview.png') 0 0 no-repeat;
				position: absolute;
				top: calc(50% - 40px);
				left: calc(50% - 27px);
				height: 38px;
				width: 55px;
				background-size: 55px;
			}
		}

	}
}

.list_all_video,
.list_video {
	.feature-video {
		margin-bottom: 20px;

		.name {
			text-transform: uppercase;
			color: $text-color;
			font-weight: bold;
			margin: 10px 0 10px 0;
			float: left;
			width: 100%;
		}
	}

}

.detail_video {
	.box_content {
		float: left;
		width: 100%;

		.name {
			text-transform: uppercase;
			color: $text-color;
			font-weight: bold;
			margin: 10px 0 10px 0;
			float: left;
			width: 100%;
		}

		.share-socials {
			margin: 0 0 10px 0;
			float: left;
			clear: left;
			width: 100%;

			> div {
				float: left !important;
				margin-right: 5px !important;
			}

			.fb-like {
				vertical-align: top;
			}

			.email {
				img {
					height: 20px;
					margin-right: 5px;
				}
			}

			.social {
				.addthis_counter.addthis_pill_style a.addthis_button_expanded {
					width: 39px !important;
				}
			}

			.embed-responsive {
				padding-bottom: 20px;
			}
		}
	}

	.related {
		float: left;
		width: 100%;

		header.related-title {
			font-size: 15px;
			font-weight: bold;
			margin: 30px 0 10px 0;
			float: left;
			width: 100%;
		}
	}
}