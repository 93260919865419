.list_document,
.list_all_document {
	.owl-carousel {
		.owl-stage-outer {
			.owl-stage {
				@media screen and (min-width: $tablet) {
					transform: translate3d(0px, 0px, 0px) !important;
				}
			}
		}

		.owl-nav {
			top: -7px;

			@media screen and (min-width: $tablet + 1px) {
				display: none !important;
			}

			&.show {
				display: block !important;
			}

			.owl-prev {
				left: -15px;
			}

			.owl-next {
				right: -15px;
			}
		}
	}

	.updating {
		margin-top: 10px;
		float: left;
		width: 100%;
	}

	#tab-selector {
		.searchbar {
			form {
				float: left;

				#keyword {
					border: none;
					height: 23px;
					vertical-align: top;
					float: left;
				}

				.submit-btn {
					float: left;
					background: yellow;
					border: none;
					height: 23px;
					border-left: 1px solid $divider-color;
				}
			}
		}
	}

	.thumb {
    float: left;
    margin-right: 10px;
	}
}