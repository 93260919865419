#flashMessage {
	margin: 0 auto;
	width: 100%;
	padding: 5px;
	position: fixed;
	top: 0;
	z-index: 10000;

	span {
		border-radius: 3px;
		padding-left: 32px;
		display: block;
		width: 470px;
		line-height: 35px;
		margin: 0 auto;
		color: white;
		box-shadow: 0 0 3px $divider-color;
	}

	&.success {
		span {
			background-image: url('img/success.png');
			background-position:  8px center;
			background-repeat: no-repeat;
			background-color: green;
		}
	}

	&.error {
		span {
			background-image: url('img/error.png');
			background-position:  8px center;
			background-repeat: no-repeat;
			background-color: red;
		}
	}
}
