.detail_gallery {
	.photo-container {
		#list-photo {
			padding: 0 15px;
		}

		.item_photo {
			padding: 0;
			margin: 0;

			@media screen and (max-width: $tablet) {
				text-align: center;
			}

			.title {
				margin: 15px 0 15px 0;
				color: $text-color;
			}

			a {
				text-align: center;
				width: 100%;

				img {
					display: inline-block;
					width: 100%;
					margin-bottom: 5px;
				}
			}
		}
	}

	.related {
		float: left;
		width: 100%;

		header.related-title {
			font-size: 15px;
			font-weight: bold;
			margin: 10px 0 10px 0;
			float: left;
			width: 100%;
		}

		ul {
			margin: 10px 0 0 0;
			padding: 0 0 0 12px;
			float: left;
			width: 100%;
		}
	}

	.share-socials {
		margin: 0 0 10px 0;
		float: left;
		clear: left;
		width: 100%;

		> div {
			float: left !important;
			margin-right: 5px !important;
		}

		.fb-like {
			vertical-align: top;
		}

		.email {
			img {
				height: 20px;
				margin-right: 5px;
			}
		}

		.social {
			.addthis_counter.addthis_pill_style a.addthis_button_expanded {
				width: 39px !important;
			}
		}

		.embed-responsive {
			padding-bottom: 20px;
		}
	}
}

.list_all_gallery,
.list_gallery {
	.img-wrapper {
		display: inline-block;
		overflow: hidden;

		img {
			transition: all .8s ease-in-out;

			&:hover {
				transform:scale(1.05);
				-ms-transform:scale(1.05); /* IE 9 */
				-moz-transform:scale(1.05); //Firefox
				-webkit-transform:scale(1.05); /* Safari and Chrome */
				-o-transform:scale(1.05); /* Opera */
				opacity: 0.7;
			}
		}
	}

	.feature-category {
		margin-bottom: 50px;

		.name {
			text-transform: uppercase;
			color: $text-color;
			font-weight: bold;
			margin: 10px 0 10px 0;
			float: left;
			width: 100%;
		}
	}

	.box_gallery {
		margin-bottom: 15px;

		.name {
			margin: 10px 0 10px 0;

			a {
				color: $text-color;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}