#comment {
	float: left;
	width: 100%;
	margin: 0;
	padding: 0 5px;

	#loading_comment {
		float: left;
		width: 100%;

		.comment {
			list-style: none;
			margin: 10px 0 0 0;
			padding: 0;
			float: left;
			width: 100%;

			li {
				margin: 10px 0 0 0;
				float: left;
				width: 100%;

				.avatar {
					height: 50px;
					width: 50px;
					background-image: url('img/noavatar.png');
					background-repeat: no-repeat;
					background-size: cover;
					float: left;
					margin-right: 10px;
				}

				.content {
					float: left;

					.name {
						font-weight: bold;

						.time {
							font-weight: normal;
							font-size: 11px;
							color: #999;
						}
					}
				}
			}
		}

		.paginator {
			float: left;
			width: 100%;
		}
	}

	#write_comment {
		font-weight: bold;
		border-bottom: 1px solid $divider-color;
		padding: 10px 0 5px 0;
		margin: 0 0 20px 0;
		float: left;
		width: 100%;
	}

	#form_comment {
		float: left;
		width: 100%;
	}
}