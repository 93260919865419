.home {
	.owl-stage-outer {
		width: calc(100% + 6px);

		.hover-border {
			border: 1px solid white;
			margin-bottom: 3px;
			box-shadow: 3px 2px 3px white;

			&:hover {
				border: 1px solid $divider-color;
				box-shadow: 3px 2px 3px #c1c1c1;
				width: calc(100% + 1px);
				padding-right: 16px;
			}
		}
	}

	.box_banner {
		margin-bottom: 10px;
		position: relative;
		padding: 0 30px;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			overflow: hidden;
			padding: 0;
			height: 160px !important;

			li {
				float: left;
				border: 1px solid $divider-color;
				margin: 0 5px;
				text-align: center;

				img {
					display: inline-block;
				}
			}
		}

		.btn-prev {
			background: url('img/owl-left.png');
			background-repeat: no-repeat;
			width: 35px;
			height: 30px;
			background-size: 9px auto;
			background-position: 15px 0px;
			position: absolute;
			left: 0;
			top: 58px;
			cursor: pointer;
		}

		.btn-next {
			background: url('img/owl-right.png');
			background-repeat: no-repeat;
			width: 35px;
			height: 30px;
			background-size: 9px auto;
			background-position: 15px 0px;
			position: absolute;
			right: 0;
			top: 58px;
			cursor: pointer;
		}

		.caroufredsel_wrapper {
			width: 100% !important;
		}
	}

	#box_product_slide {
		margin-top: 10px;

		.tab-list {
			margin: 0;
			padding: 0;
			float: left;
			width: 100%;
			border-bottom: 1px solid $brand-1-color;

			li {
				list-style: none;
				float: left;
				background-color: transparent;
				color: $text-color;
				padding: 7px 15px;
				cursor: pointer;
				text-transform: uppercase;

				@media screen and (max-width: $tablet) {
					width: 100%;
					padding: 7px 0px;
					font-size: 14px;
					text-align: center;
				}

				@media screen and (max-width: 340px) {
					font-size: 13px;
				}

				h2 {
					font-size: inherit;
					line-height: inherit;
				}

				&.active {
					background-color: $brand-2-color;
					color: white;
				}

				a {
					color: white;
				}

				&:last-child {
					float: right;
					background-color: transparent;
					padding: 7px 10px;
					text-transform: none;

					.fa {
						color: $text-color;
						margin-left: 5px;
					}

					a {
						color: $brand-3-color;
					}
				}

				&.view-all {
					@media screen and (max-width: $tablet) {
						display: none;
					}
				}
			}
		}

		.tab-content {
			display: none;
		}
	}

	// Danh mục sản phẩm nổi bật trên trang chủ cùng sản phẩm
	.category_feature {
		.heading {
			text-transform: uppercase;
			color: white;
			font-weight: bold;
			border-bottom: 1px solid $divider-color;
			margin: 20px 0 0 0;
			float: left;
			width: 100%;

			.left {
				padding: 0;

				a {
					color: white;
					float: left;
					width: 100%;
					padding-right: 24px;

					@media screen and (max-width: $tablet) {
						padding-right: 0;
					}
				}

				.fa {
					margin-right: 5px;
					float: left;
					line-height: 20px;
				}

				h2 {
					font-size: inherit;
					line-height: inherit;
					float: left;
					width: calc(100% - 20px);
				}
			}

			.name {
				padding: 7px 10px;
				background-color: $brand-2-color;
				float: left;
				width: 100%;
			}


			.view-all {
				color: $brand-3-color;
				text-transform: none;
				font-weight: normal;
				font-size: 12px;
				float: right;
				margin-top: 6px;

				@media screen and (max-width: $tablet) {
					color: #fff;
					margin-top: -26px;
				}

				.fa {
					color: $text-color;
					margin-left: 5px;
					@media screen and (max-width: $tablet) {
						color:#fff;
					}
				}
			}
		}

		.products {
			@media screen and (max-width: $tablet) {
				.c-product {
					display:none;
				}
				.c-product:nth-child(1){
					display: block;
				}
				.c-product:nth-child(2){
					display: block;
				}
			}
			.title-name {
				font-size: 14px;

				a.name {
					height: 34px;

					h3 {
						font-size: inherit;
						line-height: inherit;
						margin: inherit;
						font-weight: bold;
					}
				}
			}
		}

		aside.box.category {
			margin-bottom: 0;

			#tree {
				.nav {
					li {
						&:nth-child(n+6) {
							display: none;
						}

						a {
							font-size: 13px;
							padding: 2px 10px;
							text-transform: none;
						}
					}
				}
			}

			.show-more {
				font-size: 20px;

				.fa {
					font-size: 20px;
					color: $brand-2-color;
					cursor: pointer;
				}
			}
		}
	}
}

.home,
.list_product {
	.heading {
		border-bottom: 2px solid $brand-1-color;
		margin: 20px 0 5px 0;
		float: left;
		width: 100%;

		&.best-buy {
			@media screen and (max-width: $tablet) {
				margin-top: 0;
			}
		}
	}
}
