body {
	overflow-x: -moz-hidden-unscrollable;
	line-height: 20px;
	font-size: 13px;
	margin: 0;
	padding: 0;
	color: $text-color;


	> .embed-responsive {
		padding: 0;
		display: none;
	}

	#content {
		min-height: calc(100vh - 312px);
		margin: 0;
		float: left;
		width: 100%;
	}

	header.title {
		border-bottom: 1px solid $brand-1-color;
		padding: 0;
		margin: 0 0 15px 0;
		text-transform: uppercase;
		line-height: 20px;

		h1,
		h2 {
			font-weight: bold;
			font-size: 15px;
		}

		.sort {
			text-transform: none;

			@media screen and (max-width: $tablet) {
				position: static;
				display: block;
				float: left;
				width: 100%;
				margin: 10px 0 0 0;
			}

			button {
				background-color: #ebebeb;
				border: 1px solid $text-color;
				color: $text-color;
				border-radius: 0;
				padding: 2px 5px;
				line-height: 12px;
				font-size: 12px;
			}

			> li {
				float: left;

				&:last-child {
					margin-left: 5px;
				}
			}

			.dropdown-menu {
				border-radius: 0;

				li {
					float: left;
					width: 100%;

					a {
						padding: 0 10px;
					}
				}
			}
		}
	}

	a {
		color: $text-color;

		&:hover,
		&:active,
		&:focus {
			text-decoration: none !important;
			color: $brand-2-color;
		}
	}

	#call {
		position: fixed;
		bottom: 15px;
		display: block;
		left: 15px;
		z-index: 123;

		@media screen and (min-width: $tablet + 1px) {
			display: none !important;
		}

		.hotline {
			width: 50px;
		}
	}

	// Sản phẩm chạy ngang
	.owl-theme {
		.owl-dots {
			.owl-dot {
				&.active {
					span {
						background-color: $brand-2-color;
					}
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: calc(50% - 75px);
			left: 0;
			width: 100%;
			display: block !important;

			@media screen and (max-width: $mobile) {
				top: calc(50% - 50px);
			}

			.glyphicon {
				color: white;
			}

			.owl-prev {
				background: #ccc;
				position: absolute;
				left: 0;

				@media screen and (max-width: $mobile) {
					left: 0;
					right: auto;
				}

				&:hover {
					background: #ccc;
				}


				.owl-left {
					background: url('img/owl-left.png');
					background-repeat: no-repeat;
					width: 35px;
					height: 40px;
					background-size: 13px auto;
					background-position: 12px 0px;
				}
			}

			.owl-next {
				background: #ccc;
				position: absolute;
				right: 0;

				@media screen and (max-width: $mobile) {
					right: 0;
				}

				&:hover {
					background: #ccc;
				}

				.owl-right {
					background: url('img/owl-right.png');
					background-repeat: no-repeat;
					width: 35px;
					height: 40px;
					background-size: 13px auto;
					background-position: 12px 0px;
				}
			}
		}
	}

	.c-product {

		.thumb:hover {
			cursor: pointer;

			.mask {
				opacity: 1;
			}
		}

		.price {
			del,
			span {
				font-size: 12px;
				width: 100%;

				@media screen and (max-width: $tablet) {
					width: 100%;
				}

			}
		}

		&.run {
			.price {
				del,
				span {
					font-size: 13px;
					width: 100%;

					@media screen and (max-width: $tablet) {
						width: 100%;
						font-size: 13px;
					}
				}
			}
		}

		.title-name {
			margin: 8px 0;

			@media screen and (max-width: $tablet) {
				font-size: 15px;
			}

		}

		.mask {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.65);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			transition: all .6s ease .10s;
			opacity: 0;

			button {
				display: block;
				width: 112px;
				height: 28px;
				background-color: red;
				border: none;
				border-radius: 3px;
				color: white;
				padding: 0;
				transition: all .6s ease-out;
				margin-bottom: 0;
				box-shadow: 1px 1px 1px #e0e0e0;

				&:hover {
					transition: none;
				}

				.icon_cart {
					width: 19px;
					height: 18px;
					display: inline-block;
					vertical-align: middle;
					margin-right: 5px;
				}
			}

			&:hover {
				button {
					margin-bottom: 20px;
				}
			}
		}

		.title-name {
			text-align: center;
			font-size: 14px;

			h3 {
				font-size: inherit;
				line-height: inherit;
				margin: inherit;
				font-weight: bold;
			}
		}
	}

	.line_title{
		background-color: $brand-1-color;
		height: 1px;
	}

	#sidebar-left {
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	.share-socials {
		margin-top: 5px;
		float: left;
		margin-bottom: 15px;
		width: 100%;

		> div {
			float: left !important;
			margin-right: 5px !important;
		}

		.fb-like {
			vertical-align: top;
		}

		.email {
			img {
				height: 20px;
				margin-right: 5px;
			}
		}

		#twitter-widget-0 {
			float: left;
			margin-right: 5px;
		}

		#___plusone_0 {
			float: left;
		}

		.social {
			.addthis_counter.addthis_pill_style a.addthis_button_expanded {
				width: 39px !important;
			}
		}

		.embed-responsive {
			padding-bottom: 20px;
		}
	}

	.icon-hot {
		background-position: -148px -216px;
		height: 34px;
		width: 34px;
		display: block;
		position: absolute;
		float: left;
		z-index: 1;
		left: 7px;
		top: 7px;
	}
}

// Tab sản phẩm
#content ul.tabs {
	float: left;
	margin: 0;
	padding: 0;
}

#content ul.tabs li {
	float: left;
	height: 25px;
	line-height: 25px;
	border: 1px solid $divider-color;
	margin-bottom: -1px;
	overflow: hidden;
	position: relative;
	background: white;
	margin-right: 2px;
}

#content ul.tabs li a,#content ul.tabs li {
	border-radius: 5px 5px 0 0;
}

#content ul.tabs li a {
	text-decoration: none;
	color: #000;
	display: block;
	font-size: 13px;
	padding: 0 15px;
	outline: none;
}

#content ul.tabs li a:hover {
	background-color: $brand-1-color;
	color: white;
}

#content ul.tabs li.active,
#content ul.tabs li.active a:hover {
	background-color: $brand-1-color;
	border-bottom: 1px solid white;
	font-weight: bold;
	color: white;

	a {
		color: white;
	}
}

#content {
	.tab_container {
		border: 1px solid $divider-color;
		overflow: hidden;
		clear: both;
		float: left;
		margin-bottom: 8px;
		width: 100%;

		.tab_content {
			padding: 15px;
		}
	}
}

// Phân trang
.paginator {
	height: 40px;
	line-height: 20px;
	display: block;
	padding-top: 10px;
	text-align: right;
}

.paginator span {
	margin: 1px;
}
.paginator span a{
	color: #333;
	padding: 2px 5px;
	border: 1px solid #ccc;
}
.paginator span.current,#content .paginator a:hover {
	font-weight: bold;
	padding: 2px 5px;
	border: 1px solid #ccc;
	color: #cf0000;
}

.paginator a.disabled-page {
	color: #777;
}

.paginator a.disabled-page:hover {
	text-decoration: none;
	cursor: default;
}

// Lên đầu trang
#back-top {
	bottom: 25px;
	right: 15px;
	position: fixed;
	z-index: 9999;
}

#back-top a {
	color: #BBBBBB;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 26px;
	display: block;
	text-align: center;
}

#back-top a:hover {
	color: #000000;
}

#back-top span {
	background: darken($brand-1-color, 10)  url("img/up-arrow.png") no-repeat scroll center center;
	border-radius: 5px;
	width: 45px;
	height: 45px;
	display: block;
	margin-bottom: 7px;
}

#back-top a:hover span {
	background-color: darken($brand-1-color, 5) ;
}

.breadcrumb{
	background-color: transparent;
	margin-bottom: 10px;
	padding-bottom: 0;
}

#sidebar_fixed {
	margin-top: 0;
}

#message_cart {
	display: none;
	top: 45px;
	float: right;
	position: fixed;
	z-index: 100000;
	width: 100%;
	text-align: center;

	.success {
		width: 250px;
		margin: 0 auto;
		padding: 10px 15px;
		border-radius: 3px;
		box-shadow: 0 0 1px #333;
		background: rgb(57, 169, 0);
		color: white;
		outline: none;
	}
}



.icon_cart {
	background-position: -76px -223px;
}

#call {
	position: fixed;
	bottom: 93px;
	display: block;
	right: 15px;
	z-index: 123;
}
body #call .hotline {
	width: 50px;
}
#call a {
	color: #333;
}
@media screen and (min-width: 769px) {
	#call {
		display: none;
	}
}