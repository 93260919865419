#breadcrumb {
	float: left;
	width: 100%;

	.breadcrumb {
		li {
			font-weight: bold;

			&:not(:first-child)::before {
				content: '>' !important;
				color: $brand-1-color;
			}
		}
	}
}