.product-nav-tree-slideshow-wrapper {
	@media screen and (max-width: $tablet) {
		display: none;
	}

	#product-nav-tree-slideshow {
		border: none;
		display: block;
		border-radius: 0;
		white-space: nowrap;
		font-size: 15px;
		font-weight: bold;
		background-color: $brand-2-color;
		line-height: 39px;
		margin: 0;
		padding: 0 15px;

		> a {	// Danh mục sản phẩm
			display: block;
			margin: 0;
			padding-left: 0;
			padding-right: 0;
			width: 232px;
			text-align: center;
			background-color: $brand-2-color;

			@media screen and (max-width: $tablet) {
				width: 100%;
			}
		}

		.fa {
			float: right;
			font-size: 17px;
			cursor: pointer;

			@media screen and (max-width: $tablet) {
				display: none;
			}
		}

		.caret-wrapper {
			float: right;
			padding: 2px 10px;
			cursor: pointer;

			@media screen and (min-width: $tablet + 1px) {
				display: none;
			}
		}

		> ul {
			border-radius: 0;
			padding: 0;

			> li {
				&:nth-child(n+13):not(.show-more) {
					@media screen and (min-width: $tablet + 1px) {
						display: none;
					}
				}

				&.showing-more {
					display: block !important;
				}

				> a {
					text-transform: uppercase;
					white-space: normal;
				}

				&.current {
					> a {
						color: $brand-2-color;
						font-weight: bold;
					}
				}
			}
		}

		li {
			width: 100%;
			line-height: 30px;
			padding: 0;
			border-bottom: 1px solid $divider-color;
			min-width: 260px;

			&:hover {
				> a {
					color: $brand-2-color;;
				}

				> ul {
					@media screen and (min-width: $tablet + 1px) {
						display: block;
					}
				}
			}

			&.child {
				padding: 0 10px 0 27px;
				list-style-type: circle;
				list-style-position: inside;
			}

			&:last-child {
				border-bottom: none;
			}

			a {
				color: $text-color;
				font-size: 13px;
				padding: 5px 22px;
				background-image: none;
				background-color: white;
				line-height: 22px;

				&:hover {
					color: $brand-2-color;
				}

				.fa {
					float: right;
					font-size: 20px;
					line-height: 22px;

					@media screen and (max-width: $tablet) {
						display: none;
					}
				}
			}

			> ul {
				display: none;
				position: static;
				margin: 0;
				padding: 0;
				float: left;
				box-shadow: none;
				border-left: none;
				border-right: none;
				border-bottom: none;
				border-top: 1px solid $divider-color;
				border-radius: 0;
				min-width: initial;
				position: absolute;
				top: 0;
				left: 100%;
				display: none;

				@media screen and (min-width: $tablet + 1px) {
					border: 1px solid $divider-color;
				}

				li {
					margin: 0;
					padding: 0;
					width: 100%;
					float: left;

					a {
						padding: 5px 15px;

						&:hover,
						&:focus {
							background-color: white;
							background-image: none;
							text-decoration: underline;
							color: $brand-2-color;
						}
					}
				}
			}
		}

		.submenu-caret-wrapper {
			display: inline-block;

			@media screen and (min-width: $tablet + 1px) {
				display: none;
			}

			@media screen and (max-width: $tablet) {
				padding: 0px 10px !important;
				float: right;
				display: block;
			}

			.caret {
				margin: 0 0 0 10px;

				@media screen and (max-width: $tablet) {
					margin: 0;
					height: auto;
					line-height: inherit;
					padding: 0;
					display: inline-block;
				}
			}
		}

		.show-more {
			text-align: center;
			line-height: 0;

			.fa {
				color: $brand-2-color;
				float: none;
			}
		}
	}
}


#slideshow-right {
	padding-left: 0;
	margin-left: calc(25% - 15px);
	padding-right: 0;

	@media screen and (max-width: $tablet) {
		margin: 0;
		z-index: 1;
	}

	#slideshow-wrapper {
		float: left;
		width: 100%;
		background-color: #eeeeee;
		margin-bottom: 10px;

		#slideshow {
			margin: 0;
		}

		.lSSlideOuter {
			.lslide {
				text-align: center;

				img {
					display: inline-block !important;
				}
			}
		}
	}
}


.lSSlideOuter {
	position: relative;

	.lSPager.lSpg {
		position: absolute;
		bottom: 15px;
		left: calc(50% - 15px);

		> li {
			a {
				background-color: $brand-2-color !important;
			}
		}

		> li.hover a,
		> li.active a {
			background-color: $brand-1-color !important;
		}
	}
}

.banner-under-slideshow {
	margin-bottom: 15px;

	@media screen and (max-width: $tablet + 1px) {
		display: none;
	}

	img {
		width: 33.3333%;
		float: left;

		&:nth-child(2) {
			width: calc(33.3333% - 10px);
			margin: 0 5px;
		}

		@media screen and (min-width: $tablet + 1px) {
			height: 115px !important;
		}
	}
}